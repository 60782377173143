<template>
  <div class="preview-report-popup">
    <div v-if="loading" class="loader"></div>
    <div v-else class="report" v-html="report"></div>
    <b-btn
      variant="primary"
      class="border-0 border-bottom rounded-0"
      @click="sendReport"
      :disabled="sendButtonDisabled"
    >{{ sendButtonText }}</b-btn>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  data() {
    return {
      report: '',
      sendButtonText: 'Send Report',
      sendButtonDisabled: false,
      loading: true // Добавьте состояние загрузки
    }
  },
  methods: {
    formattedText(message) {
      let formatted = message;

      const regex = /<(https?:\/\/[^\s|]+)\|([^>]+)>/g;
      formatted = formatted.replace(regex, (match, url, text) => {
        return `<a href="${url}" target="_blank">${text}</a>`;
      });

      // Регулярное выражение для поиска звездочек и преобразования их в <strong>
      formatted = formatted.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

      // Регулярное выражение для поиска ссылок после NOTE: и RESULT LINK:
      const noteRegex = /NOTE:\s*(https?:\/\/[^\s]+)/g;
      const resultLinkRegex = /RESULT LINK:\s*(https?:\/\/[^\s]+)/g;

      formatted = formatted.replace(noteRegex, (match, url) => {
        return `NOTE: <a href="${url}" target="_blank">${url}</a>`;
      });

      formatted = formatted.replace(resultLinkRegex, (match, url) => {
        return `RESULT LINK: <a href="${url}" target="_blank">${url}</a>`;
      });

      // Удаление лишних переносов строк
      formatted = formatted.replace(/\n{3,}/g, '\n\n');

      return formatted;
    },
    async sendReport() {
      try {
        this.sendButtonText = 'Sending...';
        this.sendButtonDisabled = true;
        const response = await MtApi.clientReport({ client_id: this.$attrs.params.data._id });
        const updated = this.$attrs.params.node.data;
        for (const res_key in response) {
          updated[res_key] = response[res_key];
        }
        this.$attrs.params.node.updateData(updated);
        this.sendButtonText = 'Send Report';
        this.sendButtonDisabled = false;
      } catch (err) {
        this.sendButtonText = 'Send Report';
        this.sendButtonDisabled = false;
      }
    }
  },
  async mounted() {
    try {
      this.sendButtonDisabled = true;
      const response = await MtApi.clientReport({ client_id: this.$attrs.params.data._id, preview: true });
      this.report = this.formattedText(response.report);
      this.loading = false;
      this.sendButtonDisabled = false;
    } catch (err) {
      this.sendButtonDisabled = true;
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-report-popup {
  margin: 25px;
  overflow: visible;
  .report {
    white-space: pre-wrap;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #7a85ff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 50px auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>